import React from "react"
import styled from "styled-components"
import { FaLinkedinIn, FaTwitter, FaFacebookF } from "react-icons/fa"
import PropTypes from "prop-types"

class Footer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
    }
  }

  handleInputOnChange = email => this.setState({ email })

  handleInputOnSubmit = () => this.setState({ email: "" })

  render() {
    const { NavItems } = this.props
    const { email } = this.state
    return (
      <FooterContainer>
        <FooterMain>
          {Object.entries(NavItems)
            .slice(0)
            .map(([key, val]) => {
              return key === "id" ? null : (
                <FooterColumn key={key}>
                  <FooterToggle type="checkbox" />
                  <Collapsible className="collapsible">
                    {key.replace("___", " & ").replace("_", " ")}
                  </Collapsible>
                  {val.length > 1 ? (
                    <React.Fragment>
                      <Content>
                        {val.map(({ name, link }) => (
                          <FooterLink href={link} key={name}>
                            {name}
                          </FooterLink>
                        ))}
                      </Content>
                    </React.Fragment>
                  ) : (
                    <Content>
                      <FooterLink href={key}>News & Blog</FooterLink>
                    </Content>
                  )}
                </FooterColumn>
              )
            })}
          <SocialMedia>
            <SocialMediaHeading>Stay connected</SocialMediaHeading>
            <SocialMediaSubTitle>
              Sign up to our newsletter to receive product updates and news.
            </SocialMediaSubTitle>
            <SocialMediaEmailInput
              value={email}
              placeholder="Enter your email address"
              onChange={e => this.handleInputOnChange(e.target.value)}
            />
            <SocialMediaSignUpButton onClick={this.handleInputOnSubmit}>
              Sign Up
            </SocialMediaSignUpButton>
            <SocialMediaButtons>
              <SocialMediaButtonContainer>
                <SocialMediaButton href="https://www.linkedin.com/company/kaiostechnologies">
                  <FaLinkedinIn style={{ color: "#fff", fontSize: 22 }} />
                </SocialMediaButton>
              </SocialMediaButtonContainer>
              <SocialMediaButtonContainer>
                <SocialMediaButton href="https://twitter.com/kaiOStech">
                  <FaTwitter style={{ color: "#fff", fontSize: 22 }} />
                </SocialMediaButton>
              </SocialMediaButtonContainer>
              <SocialMediaButtonContainer>
                <SocialMediaButton href="https://www.facebook.com/kaiostech/">
                  <FaFacebookF style={{ color: "#fff", fontSize: 22 }} />
                </SocialMediaButton>
              </SocialMediaButtonContainer>
            </SocialMediaButtons>
          </SocialMedia>
        </FooterMain>
        <br />
        <Divider></Divider>
        <Legal>
          <LegalList>
            <LegalItem>
              <LegalLink href="https://www.kaiostech.com/privacy-policy/">
                KaiOS Privacy Policy
              </LegalLink>
            </LegalItem>
            <LegalItem>
              <LegalLink href="https://www.kaiostech.com/terms-of-service/">
                Terms of Service
              </LegalLink>
            </LegalItem>
            <LegalItem>
              <LegalLink href="https://www.kaiostech.com/kaistore-terms-of-service/">
                KaiStore Terms of Service
              </LegalLink>
            </LegalItem>
          </LegalList>
          <Copyright>Copyright 2020 KaiOS Technologies Inc.</Copyright>
        </Legal>
      </FooterContainer>
    )
  }
}

Footer.propTypes = {
  NavItems: PropTypes.shape(),
}

Footer.defaultProps = {
  NavItems: {},
}

export default Footer

const FooterContainer = styled.div`
  background-color: #f8f8f8;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 40px 5% 100px 5%;
  margin-top: 0;
  border-top: 1px solid #aaaaaa;
  flex-shrink: 0;
  @media (max-width: 740px) {
    border-top: 0;
    padding: 20px 0px 0px 0px;
  }
`

const FooterMain = styled.div`
  display: flex;
  flex-wrap: nowrap;
  max-width: 1110px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    max-width: 960px;
  }

  @media (max-width: 1000px) {
    max-width: 720px;
    flex-wrap: wrap;
  }
`

export const FooterColumn = styled.div`
  min-width: 145px;
  flex-grow: 1;
  margin: 0px 10px;

  @media (max-width: 740px) {
    width: 100%;
    border-bottom: 1px solid #eeeeee;
    border-top: 1px solid #eeeeee;
    padding: 10px 5px;
    position: static;
    height: calc(100% - 52px);
    overflow-x: hidden;
  }
`

const FooterToggle = styled.input`
  display: none;
  position: absolute;
  width: 90%;
  height: 52px;
  margin: 0;
  cursor: pointer;
  opacity: 0;
  z-index: 2;
  @media (max-width: 740px) {
    display: block;
    &:checked + button:after {
      content: "<";
      color: #aaaaaa;
      float: right;
      font-size: 10px;
      margin: 5px;
      transform: scale(1.5, 1) rotate(90deg);
    }

    &:checked + button + div {
      transition: max-height 0.3s ease-in-out;
      max-height: 100%;
    }
  }
`

const Collapsible = styled.button`
  color: #aaaaaa;
  background-color: #f8f8f8;
  display: block;
  position: relative;
  font-size: 14px;
  line-height: 1.5;
  cursor: pointer;

  padding: 15px 0px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;

  @media (max-width: 740px) {
    color: #323232;
    font-weight: 700;
    font-size: 16px;
    position: relative;
    line-height: 24px;
    cursor: pointer;
    margin-bottom: 0;
    padding: 0;
    &:after {
      content: ">";
      color: #aaaaaa;
      float: right;
      font-size: 10px;
      margin: 5px;
      transform: scale(1.5, 1) rotate(90deg);
    }
  }
`

const Content = styled.div`
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;

  @media (max-width: 740px) {
    max-height: 0px;
    padding-bottom: 0px;
  }
`

export const FooterLink = styled.a`
  color: #3f3f3f;
  display: block;
  position: relative;
  font-size: 14px;
  line-height: 1.5;
  cursor: pointer;
  padding: 10px 0px;
  text-decoration: none;

  &:hover {
    color: #6f02b5;
    text-decoration: none;
  }
`

const LegalLink = styled.a`
  color: #3f3f3f;
  font-size: 14px;
  line-height: 21px;
  text-decoration: none;
  font-family: "Open Sans", sans-serif;

  &:hover {
    color: #6f02b5;
    cursor: pointer;
    text-decoration: none;
  }
`
const SocialMedia = styled.div`
  flex-grow: 3;
  padding-top: 15px;

  @media (max-width: 1000px) {
    justify-content: center;
    width: 100%;
    padding: 10px 20px;
    position: static;
  }
`

const SocialMediaHeading = styled.h3`
  font-size: 21px;
  color: #aaa;
  font-weight: 600;
  margin-bottom: 5px;
  font-family: "Fieldwork Geo";
`

const SocialMediaSubTitle = styled.p`
  font-size: 14px;
  line-height: 21px;
  color: #323232;
  margin-bottom: 10px;
  margin-bottom: 10px;
  padding: 0;
`

export const SocialMediaEmailInput = styled.input`
  padding: 11px 10px 11px 10px;
  border: 1px solid #aaa;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  outline: none;
`

export const SocialMediaSignUpButton = styled.button`
  border-radius: 4px;
  background: #f4f0f7;
  border: 0;
  color: #6f02b5;
  font-size: 16px;
  padding: 9px 31px 12px 31px;
  margin-top: 10px;
  margin-bottom: 30px;
  width: 100%;
  outline: none;
  font-weight: 600;

  &:hover {
    background-color: #6f02b5;
    color: #fff;
    cursor: pointer;
  }
`

const SocialMediaButtons = styled.div`
  display: flex;
`

const SocialMediaButtonContainer = styled.div`
  background-color: #aaaaaa;
  margin: 0px 8px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: #6f02b5;
    cursor: pointer;
  }
`

const SocialMediaButton = styled.a`
  display: flex;
  align-items: center;
`

const Divider = styled.hr`
  border-top: 1px solid #6f02b5;
  margin: 15px auto;
  max-width: 1110px;

  @media (max-width: 1200px) {
    max-width: 960px;
  }

  @media (max-width: 1000px) {
    // max-width: 720px;
  }
`

const Legal = styled.div`
  display: flex;
  flex-wrap: nowrap;
  max-width: 1110px;
  margin: 0 auto;

  @media (max-width: 1200px) {
    max-width: 960px;
  }

  @media (max-width: 1000px) {
    max-width: 720px;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
`

const LegalList = styled.div`
  @media (max-width: 1000px) {
    margin: 15px auto;
    display: flex;
    flex-direction: column;
  }
`

const LegalItem = styled.div`
  float: left;
  min-width: 165px;

  @media (max-width: 760px) {
    width: 100%
    margin: 15px auto;
  }
`

const Copyright = styled.p`
  float: right;
  color: #aaaaaa;
  font-family: "Fieldwork Geo";
  font-size: 14px;
  line-height: 1.5;
  margin: 0;
  margin-left: auto;

  @media (max-width: 1000px) {
    margin: 15px auto;
  }
`
