import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const MenuIcon = ({ onClickHandler }) => (
  <Container>
    <Icon className="MenuIcon" onClick={onClickHandler}>
      <div />
    </Icon>
  </Container>
)

MenuIcon.propTypes = {
  onClickHandler: PropTypes.func,
}

MenuIcon.defaultProps = {
  onClickHandler: null,
}

export default MenuIcon

const Container = styled.div`
  display: block;
  cursor: pointer;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
`

const Icon = styled.div`
  &:before,
  &:after,
  & div {
    content: "";
    display: block;
    width: 18px;
    height: 2px;
    background-color: #fff;
    margin: 4px 0px;
    transition: all 0.2s ease-in-out;
  }
`
