import React from "react"
import styled from "styled-components"
import { FaSearch, FaLinkedinIn, FaTwitter, FaFacebookF } from "react-icons/fa"
import PropTypes from "prop-types"

const Sidenav = ({ NavItems, shouldDisplay }) => (
  <Container id="SideNav" shouldDisplay={shouldDisplay}>
    {Object.entries(NavItems).map(([key, val], i) => {
      return key === "id" ? null : (
        <React.Fragment key={key}>
          <Toggle type="checkbox" />
          <Collapsible className="collapsible">
            {key.replace("___", " & ").replace("_", " ")}
          </Collapsible>
          {val.length > 1 ? (
            <React.Fragment>
              <Content>
                {val.map(({ name, link }) => (
                  <Link href={link} key={name}>
                    {name}
                  </Link>
                ))}
              </Content>
            </React.Fragment>
          ) : (
            <Content>
              <Link href={key}>News & Blog</Link>
            </Content>
          )}
        </React.Fragment>
      )
    })}
    <SideNavItem>
      <SearchForm>
        <FaSearch style={{ color: "#aaaaaa", fontSize: 25 }} />
        <SearchField type="text" placeholder="Search" />
      </SearchForm>
    </SideNavItem>
    <SideNavItem>
      <SocialItemsContainer>
        <SocialMediaButtonContainer>
          <a href="https://www.linkedin.com/company/kaiostechnologies">
            <FaLinkedinIn style={{ color: "#fff", fontSize: 22 }} />
          </a>
        </SocialMediaButtonContainer>
        <SocialMediaButtonContainer>
          <a href="https://twitter.com/kaiOStech">
            <FaTwitter style={{ color: "#fff", fontSize: 22 }} />
          </a>
        </SocialMediaButtonContainer>
        <SocialMediaButtonContainer>
          <a href="https://www.facebook.com/kaiostech/">
            <FaFacebookF style={{ color: "#fff", fontSize: 22 }} />
          </a>
        </SocialMediaButtonContainer>
      </SocialItemsContainer>
    </SideNavItem>
  </Container>
)

Sidenav.propTypes = {
  NavItems: PropTypes.shape(),
  shouldDisplay: PropTypes.bool,
}

Sidenav.defaultProps = {
  NavItems: {},
  shouldDisplay: true,
}

const Container = styled.div`
  position: fixed;
  height: calc(100% - 62px);
  top: 62px;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  transition: 0.3s;
  width: 0%;
  z-index: 3;
  overflow: scroll;

  @media (max-width: 1000px) {
    width: ${({ shouldDisplay }) => (shouldDisplay ? "100%" : "0%")};
  }
`

const SearchForm = styled.form`
  display: flex;
  align-items: center;
  padding-left: 11px;
`

const SearchField = styled.input`
  padding: 24px 10px 24px 20px;
  font-family: "Fieldwork Geo";
  font-weight: 600;
  font-size: 21px;
  border: 0;
  width: 100%;
  background-color: #fff;
  border-radius: 3px;
  outline: none;
  color: #aaaaaa;
  ::placeholder {
    font-weight: 600;
    font-size: 21px;
  }
`

const SideNavItem = styled.div`
  margin: 0;
  display: block;
  width: 100%;
  border-bottom: 1px solid #efefef;
`

const SocialItemsContainer = styled.div`
  display: flex;
  padding: 24px 10px;
`

const SocialMediaButtonContainer = styled.div`
  background-color: #aaaaaa;
  margin: 0px 8px;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Toggle = styled.input`
  display: block;
  position: absolute;
  width: 100%;
  height: 65px;
  margin: 0;
  cursor: pointer;
  opacity: 0;
  z-index: 3;
  -webkit-touch-callout: none;

  &:checked + button:after {
    content: "<";
    color: #6f02b5;
    float: right;
    font-size: 12px;
    margin: 5px;
    transform: scale(1.5, 1) rotate(90deg);
  }

  &:checked + button + div {
    max-height: 100%;
  }
`

export const Collapsible = styled.button`
  color: #6f02b5;
  display: block;
  position: relative;
  font-size: 21px;
  font-weight: 700;
  cursor: pointer;
  font-family: "Fieldwork Geo", sans-serif;
  width: 100%;
  border: none;
  border-bottom: 1px solid #efefef;
  text-align: left;
  outline: none;
  background-color: #fff;
  padding: 24px 10px;

  &:after {
    content: ">";
    color: #6f02b5;
    float: right;
    font-size: 12px;
    margin: 5px;
    transform: scale(1.5, 1) rotate(90deg);
  }

  &:focus {
    color: #000;
  }
`
Collapsible.displayName = "SidenavCollapsible"

const Content = styled.div`
  padding: 0 18px;
  max-height: 0;
  overflow: hidden;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
`

export const Link = styled.a`
  color: #6f02b5;
  display: block;
  position: relative;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer;
  padding: 15px 5px;
  text-decoration: none;
  font-family: "Fieldwork Geo", sans-serif;

  &:hover {
    color: #af8ed8;
  }
`

export default Sidenav
