import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import NavBar from "./NavBar/Navbar"
import Footer from "./Footer/Footer"
import "../css/layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      dataJson {
        NavItems {
          Explore_KaiOS {
            link
            name
          }
          Partners___Business {
            link
            name
          }
          Developers {
            link
            name
          }
          Company {
            link
            name
          }
          Blog {
            link
            name
          }
        }
      }
    }
  `)
  const NavItems = data ? data["dataJson"]["NavItems"] : {}

  return (
    <React.Fragment>
      <NavBar NavItems={NavItems} />
      {children}
      <Footer NavItems={NavItems} />
    </React.Fragment>
  )
}

export default Layout
