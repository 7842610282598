import React, { useState } from "react"
import styled from "styled-components"
import MenuIcon from "./MenuIcon"
import Sidenav from "./Sidenav"
import { FaSearch } from "react-icons/fa"
import PropTypes from "prop-types"

const Navbar = ({ NavItems }) => {
  const resetHoverIndex = () => setHoverIndex(-1)
  const [hoverIndex, setHoverIndex] = useState(-1)
  const [onNavBar, setOnNavBar] = useState(false)
  const [toggleSideNav, setToggleSideNav] = useState(false)
  const [showSearchField, setShowSearchField] = useState(false)
  return (
    <React.Fragment>
      <Topnav
        onMouseOver={() => setOnNavBar(true)}
        onMouseOut={() => setOnNavBar(false)}
      >
        <TopnavContainer>
          <a href="/">
            <Logo />
          </a>
          <Container>
            {Object.entries(NavItems).map(([key, val], i) => {
              return key === "id" ? null : (
                <NavItem key={key}>
                  {val.length > 1 ? (
                    <React.Fragment>
                      <NavItemLink
                        href="#"
                        className="nav-link"
                        onMouseEnter={() => setHoverIndex(i)}
                      >
                        {key.replace("___", " & ").replace("_", " ")}
                      </NavItemLink>
                      <Dropdown
                        className="nav-dropdown"
                        shouldDisplay={hoverIndex === i && onNavBar}
                        onMouseLeave={resetHoverIndex}
                      >
                        {val.map(({ name, link }) => (
                          <DropdownLink href={link} key={name}>
                            {name}
                          </DropdownLink>
                        ))}
                      </Dropdown>
                    </React.Fragment>
                  ) : (
                    <NavItemLink
                      href={val[0].link}
                      className="nav-link"
                      onMouseEnter={() => setHoverIndex(i)}
                    >
                      {key}
                    </NavItemLink>
                  )}
                </NavItem>
              )
            })}
            <SearchFieldItem>
              <SearchInputContainer shouldDisplay={showSearchField}>
                <SearchInput type="text" placeholder="Search..." />
              </SearchInputContainer>
              <SearchButton
                onMouseEnter={resetHoverIndex}
                onClick={() => setShowSearchField(!showSearchField)}
              >
                <SearchIcon style={{ color: "#fff", fontSize: 16 }} />
              </SearchButton>
            </SearchFieldItem>
          </Container>
          <Hamburger id="Burger">
            <MenuIcon onClickHandler={() => setToggleSideNav(!toggleSideNav)} />
          </Hamburger>
        </TopnavContainer>
      </Topnav>
      <Sidenav NavItems={NavItems} shouldDisplay={toggleSideNav} />
    </React.Fragment>
  )
}

Navbar.propTypes = {
  NavItems: PropTypes.shape(),
}

Navbar.defaultProps = {
  NavItems: {},
}

export const NavItem = styled.div`
  display: flex;
`

export const Topnav = styled.div`
  background: #6f02b5;
  position: sticky;
  padding: 0px 15px;
  top: 0;
  z-index: 20;
`

const TopnavContainer = styled.div`
  height: 65px;
  max-width: 1100px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1200px) {
    max-width: 960px;
  }

  @media (max-width: 1000px) {
    padding: 0px 10%;
  }

  @media (max-width: 760px) {
    padding: 0px 10%;
  }

  @media (max-width: 580px) {
    max-width: none;
    margin: 0px 5%;
    padding: 0px;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  float: right;
  width: 100%;
  padding: 5px 0px 0px 0px;
  width: 650px;
  @media (max-width: 1000px) {
    display: none;
  }
`

export const NavItemLink = styled.a`
  float: right;
  color: #eeeeee;
  font-family: "Fieldwork Geo";
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: #b780da;
    text-decoration: none;
  }
  &:focus {
    color: #b780da;
    text-decoration: none;
  }
`

export const Dropdown = styled.div`
  display: ${({ shouldDisplay }) => (shouldDisplay ? "block" : "none")};
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  margin-top: 30px;
  background: #fff;
  z-index: 2;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid #efefef;
  padding: 0;
  position: fixed;
`

export const DropdownLink = styled.a`
  font-family: "Fieldwork Geo", sans-serif;
  font-weight: 700;
  font-size: 16px;
  color: #aaa;
  padding: 12px 24px;
  display: block;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: #b780da;
    text-decoration: none;
    background: #f4f0f7;
  }
`

const Hamburger = styled.div`
  display: none;
  @media (max-width: 1000px) {
    display: block;
    margin: 4px 0px 4px 0px;
    float: right;
  }
`

const Logo = styled.div`
  background: url(https://www.kaiostech.com/wp-content/uploads/2019/12/KaiOS-Logo.svg)
    no-repeat left center;
  background-size: contain;
  float: left;
  width: 100px;
  height: 28px;
  padding: 0;
`

const SearchFieldItem = styled(NavItem)`
  position: relative;
  align-items: flex-end;
`

export const SearchButton = styled.button`
  cursor: pointer;
  outline: none;
  background: transparent;
  border: none;

  @media (max-width: 767px) {
    display: none;
  }
`

const SearchIcon = styled(FaSearch)`
  color: #fff;
  font-size: 16px;
  ${SearchButton}:hover & {
    fill: #b780da;
  }
`

export const SearchInputContainer = styled.div`
  width: ${({ shouldDisplay }) => (shouldDisplay ? "360px" : "0")};
  flex: 1;
  position: absolute;
  bottom: -5px;
  right: 7px;
  height: 35px;
  overflow: hidden;
  margin-right: 25px;
  transition: all 0.3s ease-in-out;
`

const SearchInput = styled.input`
  padding: 8px 10px 9px 10px;
  width: 100%;
  border: none;
`

export default Navbar
